import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import womenBras from "../assets/images/shop-womens-bras-panties.jpg";
// import menUnderwear from "../assets/images/shop-mens-underwear.jpg";

import { WOMEN_BRANDS, WOMEN_STYLES, GENDER } from "../const";

const WomenPage = () => {
  const history = useHistory();
  const [style, setStyle] = useState();
  const [gender, setGender] = useState(GENDER.WOMEN);

  useEffect(() => {
    if(style)
      history.push(`/shop/style?gender=${gender}&style=${style}`)
  }, [style])

  const handleStyle = (evt, gender) => {
    console.log(gender, evt.target.value, 'gender')
    setGender(gender)
    setStyle(evt.target.value);
  }
  return (
    <>
      <div className="columns-1 gap-0 border-b-16 border-border-dark">
        <div className="w-full border-x-2 border-border-light">
          <a href="/women">
            <img className="h-44 sm:w-1/2 sm:h-auto" src={womenBras} alt="" />
          </a>
        </div>
      </div>
      {/* <div className="border-b-20 border-primary"></div> */}
      <div className="border-y-20 border-primary">
        <div className="grid grid-cols-1 gap-0 my-2">
          <select
            id="small-select"
            name="small-select"
            className="my-1 block pl-2 pr-6 py-1 max-w-56 border border-gray-300 bg-white text-xs rounded-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
            onChange={(evt) => handleStyle(evt, GENDER.WOMEN)}
          >
            <option>Women's styles</option>
            {
              WOMEN_STYLES.map((item, key) => {
                return <option key={key} value={item.value}>{item.label}</option>
              })
            }
          </select>
        </div>
      </div>
      <div className="grid grid-cols-1 gap-0 border-b-20 border-primary">
        <div>
          <p className="pl-2 bg-border-dark text-white">By Brand - For Her</p>
          <div className="grid grid-cols-2 py-4 pl-2 text-xs">
            <ul>
              {
                WOMEN_BRANDS.LINE1.map((item, key) => {
                  return <li key={key}><a href={`/shop/brand?gender=${GENDER.WOMEN}&brand=${item.value}`} className="text-indigo-800">{item.label}</a></li>;
                })
              }
            </ul>
            <ul>
              {
                WOMEN_BRANDS.LINE2.map((item, key) => {
                  return <li key={key}><a href={`/shop/brand?gender=${GENDER.WOMEN}&brand=${item.value}`} className="text-indigo-800">{item.label}</a></li>;
                })
              }
            </ul>
          </div>
        </div>
      </div>
    </>
  )
}

export default WomenPage
