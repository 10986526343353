import AmoenaImg from "../assets/images/brand/amoena-2244-bra.jpg";
import BtemptImg from "../assets/images/brand/b-tempt-d-wacoal-banner.jpg";
import BaliportraitImg from "../assets/images/brand/baliportrait.jpg";
import CkwomenImg from "../assets/images/brand/ck-womens.jpg";
import CarnivalImg from "../assets/images/brand/carnival.jpg";
import ChantelleTshirtImg from "../assets/images/brand/chantelle-graphie-t-shirt-bra.jpg"
import CommandoTshirtImg from "../assets/images/brand/commando-low-rise-girl-short-panties.jpg";
import CosabellaImg from "../assets/images/brand/cosabellasoiregstring.jpg";
import DolceImg from "../assets/images/brand/dolce-and-gabbana.jpg";
import DominiqueImg from "../assets/images/brand/dominique-lingerie.jpg";
import ElomiImg from "../assets/images/brand/elomi-bras.jpg";
import FantasieImg from "../assets/images/brand/fantasie.jpg";
import FelinaImg from "../assets/images/brand/felina.jpg";
import FreePeopleImg from "../assets/images/brand/free-people.jpg";

import FreyImg from "../assets/images/brand/freya-lingerie.jpg";
import GlamoriseImg from "../assets/images/brand/glamorise-bras.jpg";

export const HEADER_BRAND_LOGO_CONTENT = {
    // women brand
    '1-Adore_Me': {
        cover: '',
        description: 'Adore Me'
    },
    '1-Agent_Provocateur': {
        cover: '',
        description: 'Agent Provocateur'
    },
    '1-Alala': {
        cover: '',
        description: 'Alala'
    },
    '1-Amoena': {
        cover: AmoenaImg,
        description: "Amoena bras are created for small figure women who want style and comfort. Amoena small cup bras are designed with the A cup wearer in mind, and includes mastectomy bras."
    },
    '1-Aubade': {
        cover: '',
        description: ''
    },
    '1-b.tempt’d': {
        cover: BtemptImg,
        description: "B.tempt'd by Wacoal has seductive and sexy bras and panties."
    },
    '1-Bali': {
        cover: BaliportraitImg,
        description: "Bali bras and panties are best sellers in department stores, which is a testament to the quality, style and comfort."
    },
    '1-Bravado_Designs': {
        cover: '',
        description: 'Bravado Designs'
    },
    '1-Calvin_Klein': {
        cover: CkwomenImg,
        description: "Calvin Klein women's bras and panties are top of the line in comfort, style and aesthetics. Calvin Klein lingerie is made up of bras, panties, bikinis, hipsters and thongs - available in cotton, seamless microfiber or low rise."
    },
    '1-Carnival': {
        cover: CarnivalImg,
        description: "Brides-to-be and full-figured women love Carnival bras, corsets and camisoles because they create pretty white bras, often with lace detailing and long line bras that offer slimming results and support."
    },
    '1-Chantelle': {
        cover: ChantelleTshirtImg,
        description: "Chantelle lingerie bras and panties are sophisticated elegance with a modern flair for the woman that is today. Chantelle lingerie."
    },
    '1-Commando': {
        cover: CommandoTshirtImg,
        description: 'Commando panties and thongs have seamless construction without elastic or trim.  Feels as if you really are going "commando".'
    },
    '1-Cosabella': {
        cover: CosabellaImg,
        description: "Cosabella panties, thongs and camisoles are worn by many celebrities because of the wonderful fit, high quality materials and sexy silhouettes."
    },
    '1-DearKate': {
        cover: '',
        description: 'DearKate'
    },
    '1-Dolce + Gabbana': {
        cover: DolceImg,
        description: "D & G Dolce and Gabbana lingerie, bras and panties are a must for the fashion-forward woman  with an edge."
    },
    '1-Dominique': {
        cover: DominiqueImg,
        description: "For 50 years Dominique intimate apparel has been made with the highest level of craftsmanship and the finest materials."
    },
    '1-Dreamgirl': {
        cover: '',
        description: 'Dreamgirl'
    },
    '1-Eberjey': {
        cover: '',
        description: 'Eberjey'
    },
    '1-Elomi': {
        cover: ElomiImg,
        description: "bras and panties are made for full figure women who appreciate comfort and style at an affordable price."
    },
    '1-Eres': {
        cover: '',
        description: 'Eres'
    },
    '1-Fantasie': {
        cover: FantasieImg,
        description: "Fantasie of England lingerie direct from England is very high quality with unique designs and styles that will separate you from the bunch."
    },
    '1-Felina': {
        cover: FelinaImg,
        description: "Felina lingerie reflects the styles and distinction of Old Hollywood with a modern and sexy twist."
    }, '1-Fleur_du_Mal': {
        cover: '',
        description: 'Fleur du Mal'
    },
    '1-Flora': {
        cover: '',
        description: 'Flora'
    },
    '1-Free_People': {
        cover: FreePeopleImg,
        description: "Free People is hippy chic clothing for women with colorful camisoles and tank tops"
    },
    '1-Freya': {
        cover: FreyImg,
        description: 'Freya lingerie bras and panties are specifically designed to fit the needs of the modern, full-busted woman. While they are comfortable and supportive, Freya Bras and panties stylishly reflect contemporary trends.'
    },
    '1-Glamorise': {
        cover: GlamoriseImg,
        description: 'Glamorise plus size bras, panties and lingerie are designed for the full- figure woman. Great quality with style and comfort from Glamorise.'
    },
    '1-Goddess': {
        cover: '',
        description: ''
    },
    '1-Hanky_Panky': {
        cover: '',
        description: ''
    },
    '1-Hanes': {
        cover: '',
        description: ''
    },
    '1-Hanro': {
        cover: '',
        description: ''
    },
    '1-Hauty': {
        cover: '',
        description: ''
    },
    '1-Holiday_Lane': {
        cover: '',
        description: 'Holiday Lane'
    },
    '1-Honeydew': {
        cover: '',
        description: ''
    },
    '1-Ingrid + Isabel': {
        cover: '',
        description: 'Ingrid + Isabel'
    },
    '1-Jezebel': {
        cover: '',
        description: ''
    },
    '1-Jockey': {
        cover: '',
        description: ''
    },
    '1-Kiki_De_Montparnasse': {
        cover: '',
        description: 'Kiki De Montparnasse'
    },
    '1-Kindred_Bravely': {
        cover: '',
        description: 'Kindred Bravely'
    },
    '1-La_Perla': {
        cover: '',
        description: ''
    },
    '1-Lauren_Ralph_Lauren': {
        cover: '',
        description: 'Lauren Ralph Lauren'
    },
    '1-Le_Mystere': {
        cover: '',
        description: ''
    },
    '1-Lechery': {
        cover: '',
        description: 'Lechery'
    },
    '1-Leonisa': {
        cover: '',
        description: 'Leonisa'
    },
    '1-Lilyette': {
        cover: '',
        description: 'Lilyette'
    },
    '1-Linea_Donatella': {
        cover: '',
        description: 'Linea Donatella'
    },
    '1-Lively': {
        cover: '',
        description: 'Lively'
    },
    '1-Maidenform': {
        cover: '',
        description: ''
    },
    '1-MeMoi': {
        cover: '',
        description: 'MeMoi'
    },
    '1-Natori': {
        cover: '',
        description: ''
    },
    '1-Nike': {
        cover: '',
        description: 'Nike'
    },
    '1-Oh_La_La_Cheri': {
        cover: '',
        description: 'Oh La La Cheri'
    },
    '1-Olga': {
        cover: '',
        description: ''
    },
    '1-On_Gossamer': {
        cover: '',
        description: ''
    },
    '1-Paramour': {
        cover: '',
        description: 'Paramour'
    },
    '1-Parfait': {
        cover: '',
        description: 'Parfait'
    },
    '1-Playtex': {
        cover: '',
        description: ''
    },
    '1-Risque': {
        cover: '',
        description: 'Risque'
    },
    '1-Simone_Perele': {
        cover: '',
        description: ''
    },
    '1-Spanx': {
        cover: '',
        description: ''
    },
    '1-Splendid': {
        cover: '',
        description: ''
    },
    '1-Stance': {
        cover: '',
        description: ''
    },
    '1-State_of_Day': {
        cover: '',
        description: ''
    },
    '1-Stems': {
        cover: '',
        description: 'Stems'
    },
    '1-Vanity_Fair': {
        cover: '',
        description: ''
    },
    '1-Wacoal': {
        cover: '',
        description: ''
    },
    '1-Warners': {
        cover: '',
        description: ''
    },
    '1-Wolford': {
        cover: '',
        description: ''
    },
    '1-ZooZatz': {
        cover: '',
        description: 'ZooZatz'
    },

    // Men brand
    '2-2UNDR': {
        cover: '',
        description: '2UNDR'
    },
    '2-2xist': {
        cover: '',
        description: ''
    },
    '2-Addicted': {
        cover: '',
        description: ''
    },
    '2-Adidas': {
        cover: '',
        description: ''
    },
    '2-Agacio': {
        cover: '',
        description: 'Agacio'
    },
    '2-Andrew_Christian': {
        cover: '',
        description: ''
    },
    '2-Balenciaga': {
        cover: '',
        description: 'Balenciaga'
    },
    '2-Calvin_Klein': {
        cover: '',
        description: ''
    },
    '2-Candyman': {
        cover: '',
        description: 'Candyman'
    },
    '2-C-IN2': {
        cover: '',
        description: ''
    },
    '2-Clever': {
        cover: '',
        description: ''
    },
    '2-Cocksox': {
        cover: '',
        description: 'Cocksox'
    },
    '2-Cosabella': {
        cover: '',
        description: 'Cosabella'
    },
    '2-CR7': {
        cover: '',
        description: 'CR7'
    },
    '2-Daniel_Alexander': {
        cover: '',
        description: 'Daniel Alexander'
    },
    '2-Diesel': {
        cover: '',
        description: ''
    },
    '2-Dolce + Gabbana': {
        cover: '',
        description: ''
    },
    '2-Doreanse': {
        cover: '',
        description: 'Doreanse'
    },
    '2-Emporio_Arman': {
        cover: '',
        description: ''
    },
    '2-Ergowear': {
        cover: '',
        description: 'Ergowear'
    },
    '2-Falke': {
        cover: '',
        description: 'Falke'
    },
    '2-Fruit_of_the_Loom': {
        cover: '',
        description: ''
    },
    '2-Go_Softwear': {
        cover: '',
        description: ''
    },
    '2-Gregg_Homme': {
        cover: '',
        description: ''
    },
    '2-Hanes': {
        cover: '',
        description: ''
    },
    '2-Hanro': {
        cover: '',
        description: ''
    },
    '2-HOM_USA': {
        cover: '',
        description: ''
    },
    '2-Hugo_Boss': {
        cover: '',
        description: ''
    },
    '2-Intyman': {
        cover: '',
        description: 'Intyman'
    },
    '2-Jack_Adams': {
        cover: '',
        description: 'Jack Adams'
    },
    '2-Jockey': {
        cover: '',
        description: ''
    },
    '2-Joe_Snyder': {
        cover: '',
        description: ''
    },
    '2-JOR': {
        cover: '',
        description: 'JOR'
    },
    '2-Mack_Weldon': {
        cover: '',
        description: 'Mack_Weldon'
    },
    '2-Magic_Silk': {
        cover: '',
        description: ''
    },
    '2-Male_Basics': {
        cover: '',
        description: 'Male_Basics'
    },
    '2-Male_Power': {
        cover: '',
        description: ''
    },
    '2-Michael_Kors': {
        cover: '',
        description: 'Michael_Kors'
    },
    '2-MOB': {
        cover: '',
        description: 'MOB'
    },
    '2-Mundo_Unico': {
        cover: '',
        description: ''
    },
    '2-Munsingwear': {
        cover: '',
        description: ''
    },
    '2-Obviously': {
        cover: '',
        description: ''
    },
    '2-Papi': {
        cover: '',
        description: ''
    },
    '2-Paul_Smith': {
        cover: '',
        description: 'Paul_Smith'
    },
    '2-Perry_Ellis': {
        cover: '',
        description: 'Perry_Ellis'
    },
    '2-Petit_Q': {
        cover: '',
        description: 'Petit_Q'
    },
    '2-Pikante': {
        cover: '',
        description: ''
    },
    '2-Polo_Ralph_Lauren': {
        cover: '',
        description: ''
    },
    '2-PPV': {
        cover: '',
        description: ''
    },
    '2-Tom_Ford': {
        cover: '',
        description: 'Tom_Ford'
    },
    '2-Tommy_Hilfiger': {
        cover: '',
        description: ''
    },
    '2-UGG': {
        cover: '',
        description: 'UGG'
    },
    '2-Under_Armour': {
        cover: '',
        description: ''
    },
    '2-Pump!': {
        cover: '',
        description: 'Pump!'
    },
    '2-Saxx': {
        cover: '',
        description: 'Saxx'
    },
    '2-Supawear': {
        cover: '',
        description: 'Supawear'
    },
    '2-Versace': {
        cover: '',
        description: ''
    },
    '2-Xtremen': {
        cover: '',
        description: 'Xtremen'
    },
    '2-Zegna': {
        cover: '',
        description: 'Zegna'
    }
}