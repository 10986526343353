import { AFFILIATE_ID } from "./const/index";

export const createAffiliateLink = (amazonUrl, affiliateId = AFFILIATE_ID) => {
    let simplifiedLink = amazonUrl;
    const match = amazonUrl.match(/\/dp\/([A-Z0-9]+)/);

    if (!match) {
        simplifiedLink = amazonUrl;
    } else {
        const productId = match[1];
        simplifiedLink = `https://www.amazon.com/dp/${productId}`;
    }

    if (simplifiedLink.includes("?")) {
        return `${simplifiedLink}&tag=${affiliateId}`;
    } else {
        return `${simplifiedLink}?tag=${affiliateId}`;
    }
}