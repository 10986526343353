import React, { useEffect, useState } from "react";
import { useParams, useLocation } from "react-router-dom";

import axios from "axios";
import Pagination from "../../components/pagination.jsx";
// import { WOMEN_BRANDS } from "../../const/brand/women.js";

import ItemImg1 from "../../assets/images/calvin-klein-crew-undershirts.jpg";
// import ItemImg2 from "../../assets/images/calvin-klein-v-tshirts.jpg";
// import ItemImg3 from "../../assets/images/calvin-klein-v-undershirts.jpg";
import { GENDER, HEADER_STYLE_LOGO_CONTENT, MEN_STYLES, WOMEN_STYLES } from "../../const/index.js";
import { createAffiliateLink } from "../../common.js";
// import { MEN_BRANDS } from "../../const/brand/men.js";
// const MenBrands = [...MEN_BRANDS.LINE1, ...MEN_BRANDS.LINE2];

const ShopStylePage = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const gender = queryParams.get("gender");
  const style = queryParams.get("style");

  // const { gender, style } = useParams();
  const { cover, description } = HEADER_STYLE_LOGO_CONTENT[gender + '-' + style];

  console.log(gender, 'gender')
  console.log(style, 'style')

  const [products, setProducts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState(48);
  const [totalPages, setTotalPages] = useState(1);

  useEffect(() => {
    handleSearch();
  }, [currentPage])

  const handlePage = (pageNum) => {
    setCurrentPage(pageNum)
  }

  const handleSearch = () => {
    axios.get('https://underneath-d8df0c8bdedb.herokuapp.com/api/products', {
      params: {
        gender,
        style,
        page: currentPage,
        limit
      }
    })
      .then(response => {
        const { totalProducts, totalPages, products } = response.data;
        setProducts(products);
        setTotalPages(totalPages);
      })
      .catch(error => {
        console.error("There was an error fetching the data!", error);
      });
  };

  return (
    <>
      <div className="grid grid-cols-4 gap-0">
        {/* Style List */}
        <div className="py-3 hidden sm:block">
          {
            gender == GENDER.MEN ? <>
              <p className="pl-2 py-2">His Styles</p>
              <div className="grid grid-cols-1 md:grid-cols-2 border-t-14 border-border-light pl-2 text-xs">
                <ul>
                  {
                    MEN_STYLES.map((item, key) => {
                      return <li key={key}><a href={`/shop/style?gender=${GENDER.MEN}&style=${item.value}`} className="text-indigo-800">{item.label}</a></li>;
                    })
                  }
                </ul>
              </div>
            </> :
              <>
                <p className="pl-2 py-2">Her Styles</p>
                <div className="grid grid-cols-1 md:grid-cols-2 border-t-14 border-border-light pl-2 text-xs">
                  <ul>
                    {
                      WOMEN_STYLES.map((item, key) => {
                        return <li key={key}><a href={`/shop/style?gender=${GENDER.WOMEN}&style=${item.value}`} className="text-indigo-800">{item.label}</a></li>;
                      })
                    }
                  </ul>
                </div>
              </>
          }
        </div>
        <div className="col-span-4 sm:col-span-3 p-6">
          {/* Advertise content */}
          <div className="flex justify-between py-3 sm:block">
            {
              cover &&
              <div className="flex justify-center">
                <div className="w-40 h-32 sm:w-64 sm:h-56 border broder-border-light">
                  <img className="w-full h-full" src={cover} alt="" />
                </div>
              </div>
            }
            <div className="flex justify-center sm:pt-3">
              <p className={`text-border-light px-3 sm:px-20 text-center ${cover ? 'text-xs' : 'text-3xl'}`}>{cover ? description : style.replace(/_/g, " ")}</p>
            </div>
          </div>
          {/* Border */}
          <div className="border-t-6 border-border-light w-7/12 mb-4"></div>
          {/* Gird Content */}
          <div className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
            {
              products.map((item, index) => {
                return (
                  <div className="flex justify-center" key={index}>
                    <div className="w-36 min-w-36 max-w-36 sm:w-48 sm:min-w-48 sm:max-w-48">
                      <div className="flex justify-center">
                        <div className="border border-border-light w-full">
                          <a href={createAffiliateLink(item.link)}>
                            <img className="w-full h:40 sm:h-52" src={item.image ? item.image : ItemImg1} alt='' />
                          </a>
                        </div>
                      </div>
                      <div className="text-xs text-primary">
                        <p>{item.title}</p>
                      </div>
                      <div className="text-xs text-border-dark">
                        <p>{item.price}</p>
                      </div>
                    </div>
                  </div>
                )
              })
            }
          </div>
          {/* Pagination */}
          <div className="py-4 flex justify-center">
            <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              handlePage={handlePage}
            />
          </div>
        </div>
      </div>
    </>
  )
}

export default ShopStylePage
